import { Route, Routes } from "react-router-dom";
import AuthLayout from "./components/auth/Layout";
import CheckAuth from "./common/check-auth.js";
import Login from "./Pages/auth/Longin.js";
import Register from "./Pages/auth/Register.js";
import { useDispatch, useSelector } from "react-redux";
import { checkAuth } from "./store/auth-slice/index.js";
import { useEffect } from "react";
import PageNotFound from "./extras/PageNotFound.js";
import UserLayout from "./components/user/Layout.js";
import AdminLayout from "./components/admin/Layout.js";
import Packages from "./Pages/user/Contribute/index.js";
import Matches from "./Pages/user/Matches/index.js";
import Discover from "./Pages/user/Discover/index.js";
import Loading from "./common/loading/Loding";
import ViewProfiles from "./Pages/admin/ViewProfiles.jsx";
import AddProfiles from "./Pages/admin/AddProfiles.jsx";
import DetailsProfile from "./Pages/admin/DetailsProfile.jsx";
import UserDashboard from './Pages/Userview/Userhome/index.js'
import Paymentplans from './Pages/Userview/Paymentplans/index.js'
import Payment from './Pages/Userview/Payment/index.js'


function App() {
  const { isAuthenticated, user, isLoading } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkAuth());
  }, [dispatch]);
  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Loading />
      </div>
    );
  }
  return (
    <Routes>
      <Route
        path="/auth"
        element={
          <CheckAuth isAuthenticated={isAuthenticated} user={user}>
            <AuthLayout />
          </CheckAuth>
        }
      >
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
      </Route>
      <Route path="/admin" element={<AdminLayout />}>
        <Route path="view" element={<ViewProfiles />} />
        <Route path="add" element={<AddProfiles />} />
        <Route path="details/:id" element={<DetailsProfile />} />
      </Route>
      <Route path="/user" element={<UserDashboard />}>
      </Route>
      <Route path="user/paymentplans" element={<Paymentplans/>}/>
      <Route path="user/payment" element={<Payment/>}/>
      <Route path="/" element={<UserLayout />}>
        <Route path="matches" element={<Matches />} />
        <Route path="discover" element={<Discover />} />
        <Route path="packages" element={<Packages />} />
      </Route>

      <Route path="/unauth-page" element={<div>Unauth</div>} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default App;
