import React from "react";
import { Outlet } from "react-router-dom";
import "../../styles/auth/login-register.css";

const Layout = () => {
  return (
    <main
      style={{
        backgroundColor: "var(--main-color)",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <section
        style={{
          borderRadius: "5px",
          backgroundColor: "var( --bg-color)",
          padding: "5rem 2rem",
          color: "#ffffff",
          maxWidth: "400px",
          margin: "0 auto",
          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.9)",
          zIndex: 1,
          display: "grid",
          width: "100%",
          overflowY: "",
        }}
      >
        <Outlet />
      </section>
    </main>
  );
};

export default Layout;
