export const SampleBioData = [
  {
    id: '1',
    fullName: "John Doe",
    maritalStatus: "Single",
    age: "26",
    dob: "08/09/1998",
    height: "5.6",
    complexion: "fair",
    education: "B.Tech",
    workJob: "yes",
    salaryIncome: "30000",
    father: "Rock Deo",
    mother: "",
    sisters: "no",
    brothers: "1",
    residence: "Hyd",
    nativePlace: "MHB",
    motherTongue: "somthing",
    dargahVisit: "yes/no",
    sect: "-",
  },
  {
    id: '2',
    fullName: "Rolex",
    maritalStatus: "Single",
    age: "40",
    dob: "08/09/1975",
    height: "5.2",
    complexion: "nop",
    education: "self employed",
    workJob: "yes",
    salaryIncome: "80000",
    father: "Rock2",
    mother: "",
    sisters: "2",
    brothers: "no",
    residence: "mhb",
    nativePlace: "hyd",
    motherTongue: "Nothing",
    dargahVisit: "yes/no",
    sect: "-",
  },
];
