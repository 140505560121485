import React, { useState, useEffect } from "react";
import { animateScroll as scroll } from "react-scroll";
import Icon4 from "../../../media/logo.JPG";
import {
  Nav,
  NavbarContainer,
  Navlogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtn,
  NavBtnLink,
} from "./NavbarElements";
import { FaBars } from "react-icons/fa";
import { HeaderPages } from "../../../config/Index";
import { Link } from "react-router-dom";

const Navbar = ({ toggle }) => {
  const [isSignedIn, setIsSignedIn] = useState(false);

  useEffect(() => {
    const userStatus = localStorage.getItem("isSignedIn");
    if (userStatus === "true") {
      setIsSignedIn(true);
    }
  }, []);

  const toggleName = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <Nav>
        <NavbarContainer>
          <Navlogo to="/" onClick={toggleName}>
            <img
              alt=""
              src={Icon4}
              style={{
                width: "40px",
                height: "40px",
                marginRight: "10px",
                borderRadius: "50%",
              }}
            />
            SS Shaadi.com
          </Navlogo>
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            {HeaderPages.map((item, index) => (
              <NavItem key={index}>
                <NavLinks
                  to={item.id}
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                  style={{
                    cursor: "pointer",
                    textTransform: "capitalize",
                  }}
                >
                  <Link to={item.id} style={{textDecoration:'none', color:'inherit'}}>{item.label}</Link>
                </NavLinks>
              </NavItem>
            ))}
          </NavMenu>
          <NavBtn>
            {isSignedIn ? (
              <Link to="/user/profile">
                <img
                  src={Icon4}
                  alt="Profile"
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                  }}
                />
              </Link>
            ) : (
              <NavBtnLink to="/auth/login">Sign In</NavBtnLink>
            )}
          </NavBtn>
        </NavbarContainer>
      </Nav>
    </>
  );
};

export default Navbar;

// import React from "react";
// import { animateScroll as scroll } from "react-scroll";
// import Icon4 from "../../../media/Couple.avif";
// import {
//   Nav,
//   NavbarContainer,
//   Navlogo,
//   MobileIcon,
//   NavMenu,
//   NavItem,
//   NavLinks,
//   NavLinksS,
//   NavBtn,
//   NavBtnLink,
// } from "./NavbarElements";
// import { FaBars } from "react-icons/fa";
// import { HeaderPages } from "../../../config/Index";
// import { Link } from "react-router-dom";

// const Navbar = ({ toggle }) => {
//   const toggleName = () => {
//     scroll.scrollToTop();
//   };

//   return (
//     <>
//       <Nav>
//         <NavbarContainer>
//           <Navlogo to="/user" onClick={toggleName}>
//             <img
//               alt=""
//               src={Icon4}
//               style={{
//                 width: "40px",
//                 height: "40px",
//                 marginRight: "10px",
//                 borderRadius: "50%",
//               }}
//             />
//             SS Shaadi.com
//           </Navlogo>
//           <MobileIcon onClick={toggle}>
//             <FaBars />
//           </MobileIcon>
//           <NavMenu>
//             {HeaderPages.map((item, index) => (
//               <NavItem key={index}>
//                 <NavLinks
//                   to={item.id}
//                   smooth={true}
//                   duration={500}
//                   spy={true}
//                   exact="true"
//                   offset={-80}
//                   style={{
//                     cursor: "pointer",
//                     textTransform: "capitalize",
//                   }}
//                 >
//                   {/* <Link
//                     to={item.path}
//                     style={{
//                       textDecoration: "none",
//                       color: "#ffff",
//                     }}
//                   > */}
//                   {item.label}
//                   {/* </Link> */}
//                 </NavLinks>
//               </NavItem>
//             ))}
//             {/*
//             <NavItem>
//               <NavLinks
//                 to="/user/matches"
//                 smooth={true}
//                 duration={500}
//                 spy={true}
//                 exact="true"
//                 offset={-80}
//               >
//                 Matches
//               </NavLinks>
//             </NavItem>
//             <NavItem>
//               <NavLinks
//                 to="/user/discover"
//                 smooth={true}
//                 duration={500}
//                 spy={true}
//                 exact="true"
//                 offset={-80}
//               >
//                 Discover
//               </NavLinks>
//             </NavItem>
//             <NavItem>
//               <NavLinks
//                 to="/user/contribute"
//                 smooth={true}
//                 duration={500}
//                 spy={true}
//                 exact="true"
//                 offset={-80}
//               >
//                 Contribute
//               </NavLinks>
//             </NavItem> */}
//           </NavMenu>
//           <NavBtn>
//             <NavBtnLink to="/auth/login">Sign In</NavBtnLink>
//           </NavBtn>
//         </NavbarContainer>
//       </Nav>
//     </>
//   );
// };

// export default Navbar;
