import React, { useState } from "react";
import CommonForm from "../../common/form.js";
import { AdminBioFromData } from "../../config/Index.js";
import { SampleBioData } from "../../sampleData/SampleBioData.jsx";
const initialState = {
  id: "",
  fullName: "",
  maritalStatus: "",
  age: "",
  dob: "",
  height: "",
  complexion: "",
  education: "",
  workJob: "",
  salaryIncome: "",
  father: "",
  mother: "",
  sisters: "",
  brothers: "",
  residence: "",
  nativePlace: "",
  motherTongue: "",
  dargahVisit: "",
  sect: "",
};

const AddProfiles = () => {
  const [bioFormData, setBioFormData] = useState(initialState);
  const [newData, setNewData] = useState(SampleBioData);

  const onSubmit = (e) => {
    e.preventDefault();
    console.log("Received data: ", bioFormData);
    setNewData((prevData) => [...prevData, bioFormData]);
    setBioFormData(initialState);
  };

  return (
    <section
      style={{
        maxWidth: 600,
        width: "100%",
        margin: "0 auto",
        padding: "20px",
        boxSizing: "border-box",
        border: "1px solid #ccc",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        backgroundColor: "var(--main-color)",
        overflow: "auto",
      }}
    >
      <p className="auth_p" style={{ fontWeight: "600" }}>
        Add Your Bio Data
      </p>
      <CommonForm
        formData={bioFormData}
        setFormData={setBioFormData}
        buttonText={"Save"}
        formControls={AdminBioFromData}
        onSubmit={onSubmit}
      />
    </section>
  );
};

export default AddProfiles;
