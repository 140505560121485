import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Icon4 from "../../../media/logo.JPG";

import {
  AppContainer,
  SidebarContainer,
  MainContent,
  ProfileList,
  ProfileCardWrapper,
  ProfileImg,
  Actions,
  LockIcon,
  Button,
  SidebarList,
  SidebarListItem,
  SidebarNav,
  HeaderContainer,
  ProfileWrapper,
  Greeting,
  SortDropdown,
  UserProfileSection,
  UserDetailsContainer,
  UserAvatar,
  DashboardCard,
  CardContainer,
} from "./UserhomeElement";

const Header = () => {
  return (
    <HeaderContainer>
      <ProfileWrapper>
        <img alt="" src={Icon4} style={{ width: "40px", height: "40px" }} />
        <span style={{ fontFamily: "Dancing Script", fontSize: "24px" }}>
          Ss.shaadi.com
        </span>
      </ProfileWrapper>

      <Greeting>Welcome back, John!</Greeting>

      {/* Search Bar */}
      <input
        type="text"
        placeholder="Search profiles..."
        style={{
          marginLeft: "20px",
          padding: "10px",
          fontSize: "16px",
          borderRadius: "8px",
          border: "1px solid #ccc",
          width: "250px",
        }}
      />

      {/* Sort Dropdown */}
      <SortDropdown style={{ marginLeft: "20px" }}>
        Sort:
        <select
          style={{ marginLeft: "10px", padding: "8px", fontSize: "16px" }}
        >
          <option>Recently added</option>
          <option>Last week</option>
          <option>All profiles</option>
        </select>
      </SortDropdown>

      {/* User Profile Section */}
      <UserProfileSection style={{ marginLeft: "auto" }}>
        <UserAvatar src="/path-to-user-profile-image.png" alt="John Smith" />
      </UserProfileSection>
    </HeaderContainer>
  );
};

const Dashboard = () => {
  return (
    <CardContainer>
      <DashboardCard>
        <h4>Your best matches</h4>
        <p>240+</p>
      </DashboardCard>
      <DashboardCard>
        <h4>Based on your interests</h4>
        <p>185+</p>
      </DashboardCard>
      <DashboardCard>
        <h4>View All</h4>
        <p>5000+ Profiles</p>
      </DashboardCard>
    </CardContainer>
  );
};

const UserDetails = () => {
  return (
    <UserDetailsContainer>
      {/* User Profile Image */}
      <img
        src="/path-to-user-profile-image.png"
        alt="User "
        style={{
          width: "100%",
          borderRadius: "10px",
          marginBottom: "20px", // Space below the image
        }}
      />

      <h3>User Details</h3>
      <p>
        <strong>Full Name:</strong> Syeda
      </p>
      <p>
        <strong>Marital Status:</strong> Unmarried
      </p>
      <p>
        <strong>Age:</strong> 22
      </p>
      <p>
        <strong>D.O.B:</strong> 21-01-1999
      </p>
      <p>
        <strong>Height:</strong> 5'4"
      </p>
      <p>
        <strong>Complexion:</strong> Fair
      </p>
      <p>
        <strong>Education:</strong> B. Pharmacy (Pursuing)
      </p>
      <p>
        <strong>Work / Job:</strong> No
      </p>
      <p>
        <strong>Salary / Income:</strong> No
      </p>

      <h4>Family Details:</h4>
      <p>
        <strong>Father:</strong> Syed Jaleel Ahmed (Businessman)
      </p>
      <p>
        <strong>Mother:</strong> Saira Banu
      </p>
      <p>
        <strong>Sisters:</strong> 1 Married (M. Tech)
      </p>
      <p>
        <strong>Brothers:</strong> 1. Syed Owaise (Pursuing MS in US)
      </p>

      <p>
        <strong>Residence:</strong> Sun City, Hyderabad
      </p>
      <p>
        <strong>Native Place:</strong> Nizamabad
      </p>
      <p>
        <strong>Mother Tongue:</strong> Urdu
      </p>
      <p>
        <strong>Do you go to Dargah:</strong> No
      </p>
      <p>
        <strong>Maslak / Sect:</strong> Sunni
      </p>
    </UserDetailsContainer>
  );
};

const ProfileCardComponent = ({ profile }) => {
  return (
    <ProfileCardWrapper>
      <div style={{ position: "relative" }}>
        <ProfileImg
          src={profile.image || "/default-profile-image.png"}
          alt={`${profile.name}`}
          style={{
            filter: profile.isPremium ? "none" : "blur(8px)",
            cursor: profile.isPremium ? "pointer" : "not-allowed",
          }}
        />
      </div>
      <h3>{profile.name}</h3>
      <p>
        <strong>Age: </strong>
        {profile.age} Yrs
      </p>
      <p>
        <strong>Religion: </strong>
        {profile.religion}
      </p>
      {/* <p><strong>Caste: </strong>{profile.caste}</p> */}
      <p>
        <strong>Occupation: </strong>
        {profile.occupation}
      </p>
      <p>
        <strong>Height: </strong>
        {profile.height}
      </p>
      <p>
        <strong>Phone Number: </strong>
        <span style={{ color: "grey" }}> 91********</span>
      </p>
      <Button>Subscribe to View Full Details</Button>
      <LockIcon>🔒</LockIcon>
      <Actions>
        <Button>Send Interest</Button>
        <Button>Send Mail</Button>
        <Button>Shortlist</Button>
        <Button className="block">Ignore</Button>
      </Actions>
    </ProfileCardWrapper>
  );
};

const ProfileListComponent = () => {
  const profiles = [
    {
      name: "Abbie Allen",
      age: 32,
      religion: "Jain - Digambar",
      occupation: "Defense Sector",
      height: "6 ft 7 in / 200 cms",
      image: "/path-to-Abbie-image.png",
      lastLogin: "4 hours ago",
      isPremium: false, // Add premium logic if needed
    },
    {
      name: "Tonya Hart",
      age: 38,
      religion: "Muslim - Sunni",
      occupation: "Art Handicraft",
      height: "5 ft 4 in / 162 cms",
      image: "/path-to-Tonya-image.png",
      lastLogin: "3 weeks ago",
      isPremium: false, // For unlocked profile
    },
  ];

  return (
    <ProfileList>
      {profiles.map((profile) => (
        <ProfileCardComponent profile={profile} key={profile.name} />
      ))}
    </ProfileList>
  );
};

const Sidebar = ({ onNavigate }) => {
  const navigate = useNavigate();

  return (
    <SidebarContainer>
      <SidebarNav>
        <SidebarList>
          <SidebarListItem onClick={() => onNavigate("profiles")}>
            Dashboard
          </SidebarListItem>
          <SidebarListItem onClick={() => navigate("paymentplans")}>
            
            Payments
           
          </SidebarListItem>
        </SidebarList>
      </SidebarNav>
    </SidebarContainer>
  );
};

const App = () => {
  return (
    <AppContainer>
      <Header />
      <Sidebar onNavigate={() => {}} />
      <MainContent>
        <Dashboard />
        <ProfileListComponent />
      </MainContent>
      <UserDetails />
    </AppContainer>
  );
};

export default App;
