import React from "react";
import {
  Container,
  FormWrap,
  FormContent,
  Form,
  H1,
  Label,
  Input,
  Button,
  Text
} from "./PaymentElements";

const Payment = () => {
  return (
    <Container id="Payment">
      <FormWrap>
        <FormContent>
          <Form action="#">
            <H1>Payment Details</H1>
            <Label htmlFor="name">Full Name</Label>
            <Input type="text" id="name" name="name" required />

            <Label htmlFor="email">Email</Label>
            <Input type="email" id="email" name="email" required />

            <Label htmlFor="card">Card Number</Label>
            <Input type="text" id="card" name="card" required />

            <Label htmlFor="expiry">Expiry Date</Label>
            <Input type="text" id="expiry" name="expiry" placeholder="MM/YY" required />

            <Label htmlFor="cvc">CVC</Label>
            <Input type="text" id="cvc" name="cvc" required />

            <Button type="submit">Pay Now</Button>
            <Text>Secure Payment Gateway</Text>
          </Form>
        </FormContent>
      </FormWrap>
    </Container>
  );
};

export default Payment;