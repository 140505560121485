import styled from 'styled-components';

export const HeroBg = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
`;

export const PricingContainer = styled.div`
  text-align: center;
  padding: 50px;
  background-color: #f0f4f8;
 font-family: safari
`;

export const SidebarContainertrong = styled.strong`
  font-family: serif
`;

export const Title = styled.h1`
  color: #2c2e48;
  font-size: 28px;
  margin-bottom: 8px;
  font-family: safari
`;

export const Title1 = styled.h2`
  font-size: 20px;
  color: #6c757d;
  margin-bottom: 30px;
 font-family: safari
`;

export const Cards = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

export const Card = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 30px;
  width: 250px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  
  ${(props) =>
        props.popular &&
        `
    transform: scale(1.05);
    background-color: #f8f9fa;
  `}
`;

export const CardTitle = styled.h3`
  font-size: 20px;
  color: #1dbf73;
  margin-bottom: 10px;
 font-family: safari
`;

export const CardSubtitle = styled.p`
  font-size: 14px;
  color: #6c757d;
  margin-bottom: 20px;
 font-family: safari
`;

export const Price = styled.div`
  font-size: 32px;
  color: #343a40;
  margin-bottom: 20px;
  span {
    font-weight: bold;
  }
`;

export const Features = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 20px;

  li {
    margin-bottom: 10px;
    color: #495057;
    font-family: safari
  }
`;

export const BuyButton = styled.button`
  background-color: #1dbf73;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  
  &:hover {
    background-color: #17a566;
  }
`;

export const Footer = styled.footer`
  margin-top: 40px;
  font-size: 14px;
  color: #6c757d;
  font-family: safari
`;
