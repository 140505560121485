import "../styles/common/forms-module.css";

const CommonForm = ({
  formControls,
  formData,
  setFormData,
  buttonText,
  onSubmit,
}) => {
  function renderInputsByComponentType(getControlItem) {
    
    let element = null;
    const value = formData[getControlItem.name] || "";

    switch (getControlItem.componentType) {
      case "input":
        element = (
          <div className="form_body">
            <label
              style={{ marginBottom: "0.5rem" }}
              htmlFor={getControlItem.name}
            >
              {getControlItem.label}
            </label>
            <input
              className="form_input"
              name={getControlItem.name}
              placeholder={getControlItem.placeholder}
              id={getControlItem.name}
              type={getControlItem.type}
              value={value}
              label={getControlItem.label}
              autoComplete=""
              onChange={(event) =>
                setFormData({
                  ...formData,
                  [getControlItem.name]: event.target.value,
                })
              }
            />
          </div>
        );
        break;
      case "select":
        element = (
          <div className="form_body">
            <label
              style={{ marginBottom: "0.5rem" }}
              htmlFor={getControlItem.name}
            >
              {getControlItem.label}
            </label>

            <select
              className="form_select"
              name={getControlItem.name}
              value={value}
              onChange={(event) =>
                setFormData({
                  ...formData,
                  [getControlItem.name]: event.target.value,
                })
              }
            >
              <option value="" disabled>
                {getControlItem.placeholder}
              </option>
              {getControlItem.options && getControlItem.options.length > 0
                ? getControlItem.options.map((optionItem) => (
                    <option key={optionItem.value} value={optionItem.value}>
                      {optionItem.label}
                    </option>
                  ))
                : null}
            </select>
          </div>
        );

        break;
      case "textarea":
        element = (
          <div className="form_body">
            <label
              style={{ marginBottom: "0.5rem" }}
              htmlFor={getControlItem.name}
            >
              {getControlItem.label}
            </label>
            <textarea
              className="form_textarea"
              name={getControlItem.name}
              placeholder={getControlItem.placeholder}
              id={getControlItem.name}
              type={getControlItem.type}
              value={value}
              label={getControlItem.label}
              onChange={(event) =>
                setFormData({
                  ...formData,
                  [getControlItem.name]: event.target.value,
                })
              }
            />
          </div>
        );
        break;
      default:
        element = (
          <div className="form_body">
            <label
              style={{ marginBottom: "0.8rem" }}
              htmlFor={getControlItem.name}
            >
              {getControlItem.label}
            </label>
            <input
              className="form_input"
              name={getControlItem.name}
              placeholder={getControlItem.placeholder}
              id={getControlItem.name}
              type={getControlItem.type}
              value={value}
              onChange={(event) =>
                setFormData({
                  ...formData,
                  [getControlItem.name]: event.target.value,
                })
              }
            />
          </div>
        );
        break;
    }
    return element;
  }

  return (
    <form onSubmit={onSubmit}>
      <div>
        {formControls.map((controlItem) => (
          <div key={controlItem.name} sx={{ mb: "10px" }}>
            {renderInputsByComponentType(controlItem)}
          </div>
        ))}
      </div>
      <button className="auth_log" variant="contained" type="submit">
        {buttonText || "submit"}
      </button>
    </form>
  );
};

export default CommonForm;
