export const registerFormControls = [
  {
    name: "name",
    label: "Name",
    type: "text",
    placesholder: "Enter your User Name",
    componentType: "input",
  },
  
  {
    name: "email",
    label: "Email",
    type: "text",
    placesholder: "Enter your Email Id",
    componentType: "input",
  },
  {
    name: "phone",
    label: "Phone",
    type: "number",
    placesholder: "Enter your Phone Number",
    componentType: "input",
  },
  {
    name: "password",
    label: "Password",
    type: "password",
    placesholder: "Create your Password",
    componentType: "input",
  },
  {
    name: "confirmpassword",
    label: "Confirm Password",
    type: "password",
    placesholder: "Create your Password",
    componentType: "input",
  },
];

export const loginFormControls = [

  {
    name: "Email",
    label: "Email",
    type: "text",
    placesholder: "Enter your Email Id",
    componentType: "input",
  },
  // {
  //   name: "phone",
  //   label: "Phone",
  //   type: "number",
  //   placesholder: "Enter your Phone Number",
  //   componentType: "input",
  // },
  {
    name: "Password",
    label: "Password",
    type: "password",
    placesholder: "Create your Password",
    componentType: "input",
  },
];

export const packagesFormData = [
  { id: 1, name: "Free", profiles: 5, price: 0 },
  { id: 2, name: "Standard", profiles: 10, price: 150 },
  { id: 3, name: "Premium", profiles: 15, price: 250 },
  { id: 4, name: "Unlimited", profiles: "Unlimited", price: 400 },
];

export const AdminBioFromData = [
  {
    name: "fullname",
    label: "Full Name",
    type: "text",
    placeholder: "Enter your Full Name", // corrected typo
    componentType: "input",
  },
  {
    name: "age",
    label: "Age",
    type: "number",
    placeholder: "Enter your Age", // corrected typo
    componentType: "input",
  },
  {
    name: "maritalStatus",
    label: "Marital Status",
    type: "select",
    placeholder: "Enter your Marital Status",
    componentType: "select",
    options: [
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" },
    ],
  },
  {
    name: "dob",
    label: "Date of Birth",
    type: "date",
    placeholder: "Enter your Date of Birth",
    componentType: "input",
  },
  {
    name: "height",
    label: "Height",
    type: "text",
    placeholder: "Enter your Height",
    componentType: "input",
  },
  {
    name: "complexion",
    label: "Complexion",
    type: "text",
    placeholder: "Enter your Complexion",
    componentType: "input",
  },
  {
    name: "education",
    label: "Education",
    type: "text",
    placeholder: "Enter your Education",
    componentType: "input",
  },
  {
    name: "workJob",
    label: "Work/Job",
    type: "text",
    placeholder: "Enter your Work or Job details",
    componentType: "input",
  },
  {
    name: "salaryIncome",
    label: "Salary/Income",
    type: "text",
    placeholder: "Enter your Salary or Income",
    componentType: "input",
  },
  {
    name: "fathername",
    label: "Father Name",
    type: "text",
    placeholder: "Enter your Father's Name",
    componentType: "input",
  },
  {
    name: "mothername",
    label: "Mother Name",
    type: "text",
    placeholder: "Enter your Mother's Name",
    componentType: "input",
  },
  {
    name: "sisters",
    label: "Sisters",
    type: "text",
    placeholder: "Enter number of Sisters / no",
    componentType: "textarea",
  },
  {
    name: "brothers",
    label: "Brothers",
    type: "text",
    placeholder: "Enter number of Brothers / no",
    componentType: "textarea",
  },
  {
    name: "residence",
    label: "Residence",
    type: "text",
    placeholder: "Enter your Residence",
    componentType: "input",
  },
  {
    name: "nativePlace",
    label: "Native Place",
    type: "text",
    placeholder: "Enter your Native Place",
    componentType: "input",
  },
  {
    name: "motherTongue",
    label: "Mother Tongue",
    type: "text",
    placeholder: "Enter your Mother Tongue",
    componentType: "input",
  },
  {
    name: "dargahVisit",
    label: "Do you visit Dargah?",
    type: "select",
    placeholder: "Yes or No",
    componentType: "select",
    options: [
      { value: "yes", label: "Yes" },
      { value: "no", label: "No" },
    ],
  },
  {
    name: "sect",
    label: "Maslak / Sect",
    type: "text",
    placeholder: "Enter your Sect",
    componentType: "input",
  },
];

export const HeaderPages = [
  // { label: "Hero", id: "hero" },
  { label: "Matches", id: "matches" },
  { label: "Discover", id: "discover" },
  { label: "Packages", id: "packages" },
];

export const AdminPages = [
  {
    label: "view profiles",
    path: "/admin/view",
  },
  {
    label: "add profiles",
    path: "/admin/add",
  },
];
