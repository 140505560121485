import React from "react";
import { Outlet, Link } from "react-router-dom";
import "../../styles/admin/admin_layout.css";
import { AdminPages } from "../../config/Index";

const Layout = () => {
  return (
    <main
      style={{
        display: "flex",
        backgroundColor: "var(--main-bg-color)",
        height: "100vh",
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <aside
        style={{
          width: "180px",
          padding: "20px",
          background: "var(--main-color)",
        }}
      >
        <nav>
          <Link className="admin_header" to={"/admin/view"}>
            <h6 style={{ margin: "0", fontWeight: 500 }}>Admin for</h6>
            <h1 style={{ margin: "0", fontWeight: 800 }}>SS Shaadi</h1>
          </Link>
          <ul
            style={{
              listStyleType: "none",
              padding: 0,
            }}
          >
            {AdminPages.map((page, index) => (
              <li className="admin_li" key={index}>
                <Link to={page.path} className="admin_pages">
                  {page.label}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </aside>
      <div
        style={{
          flexGrow: 1,
          padding: "20px",
          color: "black",
          overflowY: "scroll",
        }}
      >
        <input
          type="search"
          placeholder="Search..."
          style={{
            marginBottom: "30px",
          }}
        />

        <Outlet />
      </div>
    </main>
  );
};

export default Layout;
