import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
    HeroBg,
    PricingContainer,
    Title,
    Title1,
    Cards,
    Card,
    CardTitle,
    CardSubtitle,
    Price,
    Features,
    BuyButton,
    Footer,
} from './PaymentplansElements';

function Paymentplans() {

        const navigate = useNavigate();
      
        const handleBuyNow = (e) => {
            e.preventDefault();
            navigate("/user/payment");
        };

    return (
        <HeroBg>
            <PricingContainer>
                <Title>Pricing Packages</Title>
                <Title1>Become a member and get more benefits</Title1>
                <Cards>
                    {/* Pricing Card 1 */}
                    <Card>
                        <CardTitle>Basic Package</CardTitle>
                        <CardSubtitle>For individuals looking for few connections</CardSubtitle>
                        <Features>
                            <li>View and contact <strong>up to 5 profiles</strong></li>
                            <li>Ideal for individuals looking for <strong>a few targeted matches</strong></li>
                            <li><strong>Profile access for 30 days</strong></li>
                            <li><strong>Basic customer support</strong> for any queries and assistance</li>
                            <li><strong>Privacy features</strong> to ensure your data is secure</li>
                        </Features>
                        <Price>
                            Price <span> ₹3,000</span>
                        </Price>
                        <BuyButton  onClick={handleBuyNow} >Buy Now</BuyButton>
                    </Card>

                    {/* Pricing Card 2 */}
                    <Card>
                        <CardTitle>Standard Package</CardTitle>
                        <CardSubtitle>Perfect for those who need a moderate number of connections</CardSubtitle>
                        <Features>
                            <li>View and contact <strong>up to 15 profiles</strong></li>
                            <li>Suitable for those seeking <strong>more connections and options</strong></li>
                            <li><strong>Profile access for 60 days</strong></li>
                            <li><strong>Priority customer support</strong> for faster assistance</li>
                            <li><strong>Profile insights</strong> with key details about each match</li>
                            <li><strong>Profile recommendations</strong> based on compatibility</li>
                        </Features>
                        <Price>
                            Price <span> ₹6,000</span>
                        </Price>
                        <BuyButton  onClick={handleBuyNow} >Buy Now</BuyButton>
                    </Card>

                    {/* Pricing Card 3 */}
                    <Card>
                        <CardTitle>Premium Package</CardTitle>
                        <CardSubtitle>Perfect for small business</CardSubtitle>
                        <Features>
                            <li>View and contact <strong>up to 25 profiles</strong></li>
                            <li>Designed for individuals with <strong>serious commitment</strong> in mind</li>
                            <li><strong>Profile access for 90 days</strong></li>
                            <li><strong>Dedicated customer support</strong> for personalized assistance</li>
                            <li><strong>Advanced compatibility matching</strong> based on preferences</li>
                            <li><strong>Detailed analytics</strong> on profile engagement and potential matches</li>
                            <li><strong>Special discounts</strong> on future packages or services</li>
                        </Features>
                        <Price>
                            Price <span> ₹10,000</span>
                        </Price>
                        <BuyButton  onClick={handleBuyNow}  >Buy Now</BuyButton>
                    </Card>

                    <Card popular>
                        <CardTitle>Unlimited Package</CardTitle>
                        <CardSubtitle>Most popular package</CardSubtitle>
                        <Features>
                            <li><strong>Unlimited profile views</strong> and contact</li>
                            <li>Best for those <strong>seriously searching for their life partner</strong></li>
                            <li><strong>Unlimited access for 6 months</strong></li>
                            <li><strong>VIP customer support</strong> with a personal account manager</li>
                            <li><strong>Comprehensive matchmaking services</strong> tailored to your needs</li>
                            <li><strong>Detailed analytics</strong> on profiles you engage with</li>
                            <li><strong>Exclusive offers</strong> for events and community activities</li>
                            <li><strong>Complimentary feature upgrades</strong> and priority listings</li>
                        </Features>
                        <Price>
                            Price <span> ₹15,000</span>
                        </Price>
                        <BuyButton  onClick={handleBuyNow} >Buy Now</BuyButton>
                    </Card>
                </Cards>
                <Footer>© 2020 Modern Pricing Packages. All rights reserved | Design by W3layouts</Footer>
            </PricingContainer>
        </HeroBg>
    );
}

export default Paymentplans;
