import { useState } from "react";
import CommonForm from "../../common/form";
import { registerFormControls } from "../../config/Index";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { registerUser } from "../../store/auth-slice";

const initialState = {
  name: "",
  email: "",
  phone:'',
  password: "",
  confirmpassword: "",
};

const Register = () => {
  const [formData, setFormData] = useState(initialState);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = (e) => {
    e.preventDefault();
    navigate("/admin/view");
    dispatch(registerUser(formData)).then((data) => {
      // if (data?.payload?.success) {
      //   // toast({
      //   //   title: data?.payload?.message,
      //   // })
      //   navigate("/auth/login");
      // } else {
      //   // toast({
      //   //   title: data?.payload?.message,
      //   // })
      // }
    });
  };
  console.log(formData);

  return (
    <>
      <p className="auth_p">Create Your New Account</p>
      <CommonForm
        formControls={registerFormControls}
        buttonText={"SignUp"}
        formData={formData}
        setFormData={setFormData}
        onSubmit={onSubmit}
      />
      <p className="auth_p2">
        Already have Account?<Link to="/auth/login">Login</Link>
      </p>
    </>
  );
};

export default Register;
