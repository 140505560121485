import styled from 'styled-components';


export const AppContainer = styled.div`
  display: flex;
  padding: 20px;
  background-color: #ffff;
  justify-content: flex-start;
 `;



export const SidebarContainer = styled.div`
  width: 15%;
  background-color: #6366f1;
  padding: 20px;
  border-radius: 10px;
  margin-top: 70px; 
  margin-right: 40px; /* Space between Sidebar and Main Content */
`;


export const SidebarNav = styled.nav`
  padding: 10px;
  color: white;
`;


export const SidebarList = styled.ul`
  list-style: none;
  padding: 0;
`;


export const SidebarListItem = styled.li`
  padding: 20px;
  cursor: pointer;
  color: white;
  font-size: 18px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #4c51bf;
  }
`;


export const profile = styled.li`
   margin-left: 2rem;
  margin-top: 2rem;
  text-decoration: none;
  color: #fff;
  font-weight: 700;
  font-size: 2rem;
  font-family: "Dancing Script";
`;



export const MainContent = styled.div`
  width: 55%; 
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px; 
`;


export const HeaderContainer = styled.header`
  width: 100vw; 
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: white;
  border-radius: 0; 
  position: fixed; 
  top: 0;
  left: 0; 
  right: 0; 
  z-index: 1000; 
  gap: 160px; 
  height: 60px; 
  border: 1px solid rgba(0, 0, 0, 0.1); 
`;


export const ProfileWrapper = styled.div`
  display: flex;
  align-items: center; 
  gap: 10px; 
`;


export const Greeting = styled.h2`
  font-size: 40px;
  font-weight: semi-bold;
  font-family: "safari";

`;


export const SortDropdown = styled.div`
  font-size: 16px;
`;


export const UserProfileSection = styled.div`
  display: flex;
  align-items: center;
`;


export const UserAvatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
`;


export const CardContainer = styled.div`
  display: flex;
  justify-content: space-between; /* Space between Dashboard cards */
  width: 100%; /* Full width for the card container */
  margin-bottom: 20px;
`;


export const DashboardCard = styled.div`
  width: 30%;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
`;


export const ProfileList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;


export const ProfileCardWrapper = styled.div`
  background-color: white;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  text-align: left;
`;


export const ProfileImg = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
`;


export const Actions = styled.div`
  margin-top: 15px;
`;


export const LockIcon = styled.span`
  font-size: 18px;
  color: #007bff;
`;


export const Button = styled.button`
  margin-right: 10px;
  padding: 8px 16px;
  border: none;
  background-color: #6366f1;
  color: white;
  cursor: pointer;
  border-radius: 8px;

  &:hover {
    background-color: #4c51bf;
  }

  &.block {
    background-color: #e3342f;
  }
`;


export const UserDetailsContainer = styled.div`
  width: 30%; 
  background-color: #d3d3d3; 
  padding: 20px;
  border-radius: 10px;
  margin-top: 70px; 
  margin-left: auto;
  margin-right: 20px; 
  position: sticky; 
  top: 70px; 

  p {
    margin-bottom: 10px; 
  }

  h4 {
    margin-bottom: 10px; 
    margin-top: 20px; 
    border-bottom: 1px solid black; 
    padding-bottom: 5px; 
  }

  h3 {
    margin-bottom: 15px; 
    border-bottom: 1px solid black; 
    padding-bottom: 5px; 
  }
`;



