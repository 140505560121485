import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f4f8;
`;

export const FormWrap = styled.div`
  background: #121b3a;
  width: 400px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
`;

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const H1 = styled.h1`
  color: white;
  font-size: 24px;
  margin-bottom: 20px;
`;

export const Label = styled.label`
  color: white;
  margin: 10px 0;
  font-size: 14px;
`;

export const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin: 5px 0 20px 0;
  border: none;
  border-radius: 5px;
`;

export const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #02b875;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #02965f;
  }
`;

export const Text = styled.p`
  color: white;
  margin-top: 20px;
  font-size: 12px;
`;
