import React from "react";
import { SampleBioData } from "../../sampleData/SampleBioData";
import { FaExclamationCircle } from "react-icons/fa";
import { TbUserEdit } from "react-icons/tb";
import { MdDeleteForever } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const ViewProfiles = () => {
  const TableHeaders = [
    "Full Name",
    "Age",
    "Education",
    "Father",
    "Working",
    "Actions",
  ];

  const navigate = useNavigate();

  const handleViewMore = (id) => {
    navigate(`/admin/details/${id}`); 
  };
  const handleEdit = () => {
    console.log("Edit");
  };
  const handleDelete = () => {
    console.log("delete");
  };
  return (
    <section>
      <h2
        style={{
          color: "var(--main-color)",
        }}
      >
        List of Profile
      </h2>
      <hr />
      <div
        style={{
          borderRadius: "10px",
        }}
      >
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            marginTop: "20px",
            borderRadius: "10px ",
          }}
        >
          <thead>
            <tr style={{ backgroundColor: "#f2f2f2" }}>
              {TableHeaders.map((list, i) => (
                <th
                  key={i}
                  style={{ border: "1px solid #ccc", padding: "10px" }}
                >
                  {list}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {SampleBioData.length > 0 ? (
              SampleBioData.map((bio) => (
                <tr
                  key={bio.fullName}
                  style={{ borderBottom: "1px solid #ccc" }}
                >
                  <td style={{ border: "1px solid #ccc", padding: "10px" }}>
                    {bio.fullName}
                  </td>
                  <td style={{ border: "1px solid #ccc", padding: "10px" }}>
                    {bio.age} years old
                  </td>
                  <td style={{ border: "1px solid #ccc", padding: "10px" }}>
                    {bio.education}
                  </td>
                  <td style={{ border: "1px solid #ccc", padding: "10px" }}>
                    {bio.father}
                  </td>
                  <td style={{ border: "1px solid #ccc", padding: "10px" }}>
                    {bio.workJob}
                  </td>
                  <td style={{ border: "1px solid #ccc", padding: "10px" }}>
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          color: "black",
                          border: "none",
                          padding: "5px 10px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleViewMore(bio.id)}
                      >
                        <FaExclamationCircle />
                      </div>
                      <div
                        style={{
                          color: "black",
                          border: "none",
                          padding: "5px 10px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleEdit(bio.id)}
                      >
                        <TbUserEdit />
                      </div>
                      <div
                        style={{
                          color: "red",
                          border: "none",
                          padding: "5px 10px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleDelete(bio)}
                      >
                        <MdDeleteForever />
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="7"
                  style={{ textAlign: "center", padding: "20px" }}
                >
                  No bio data available.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default ViewProfiles;
