import React from "react";
import { useParams } from "react-router-dom";
import { SampleBioData } from "../../sampleData/SampleBioData";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import './DetailsProfile.css'; // Import the CSS file

const DetailsProfile = () => {
  const { id } = useParams();
  const profile = SampleBioData.find((bio) => bio.id === id);

  if (!profile) {
    return <div>Profile not found.</div>;
  }

  const handleShareText = () => {
    const profileDetails = `
      Full Name: ${profile.fullName}
      Age: ${profile.age} years old
      Education: ${profile.education}
      Father: ${profile.father}
      Working: ${profile.workJob}
      Marital Status: ${profile.maritalStatus}
      Date of Birth: ${profile.dob}
      Height: ${profile.height}"
      Salary: ${profile.salaryIncome}
      Mother Name: ${profile.mother}
      Sisters: ${profile.sisters}
      Brothers: ${profile.brothers}
      Residence: ${profile.residence}
      Native Place: ${profile.nativePlace}
      Mother Tongue: ${profile.motherTongue}
      Do you visit Dargah?: ${profile.dargahVisit}
    `;

    navigator.clipboard.writeText(profileDetails)
      .then(() => {
        alert("Profile details copied to clipboard!");
      })
      .catch((err) => {
        console.error("Error copying text: ", err);
      });
  };

  const handleDownloadPDF = () => {
    const input = document.getElementById("profile-card");
    
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF();
        pdf.addImage(imgData, "PNG", 10, 10);
        pdf.save(`profile_${profile.id}.pdf`);
      })
      .catch((err) => {
        console.error("Error generating PDF: ", err);
      });
  };

  return (
    <section className="details-profile">
      <div className="profile-card" id="profile-card">
        <h2>{profile.fullName}'s Profile</h2>
        <div className="profile-details">
          <table>
            <tbody>
              <tr>
                <td><strong>Age:</strong></td>
                <td>{profile.age} years old</td>
              </tr>
              <tr>
                <td><strong>Marital Status:</strong></td>
                <td>{profile.maritalStatus}</td>
              </tr>
              <tr>
                <td><strong>Date of Birth:</strong></td>
                <td>{profile.dob}</td>
              </tr>
              <tr>
                <td><strong>Height:</strong></td>
                <td>{profile.height}"</td>
              </tr>
              <tr>
                <td><strong>Education:</strong></td>
                <td>{profile.education}</td>
              </tr>
              <tr>
                <td><strong>Working:</strong></td>
                <td>{profile.workJob}</td>
              </tr>
              <tr>
                <td><strong>Salary:</strong></td>
                <td>{profile.salaryIncome}</td>
              </tr>
              <tr>
                <td><strong>Father Name:</strong></td>
                <td>{profile.father}</td>
              </tr>
              <tr>
                <td><strong>Mother Name:</strong></td>
                <td>{profile.mother}</td>
              </tr>
              <tr>
                <td><strong>Sisters:</strong></td>
                <td>{profile.sisters}</td>
              </tr>
              <tr>
                <td><strong>Brothers:</strong></td>
                <td>{profile.brothers}</td>
              </tr>
              <tr>
                <td><strong>Residence:</strong></td>
                <td>{profile.residence}</td>
              </tr>
              <tr>
                <td><strong>Native Place:</strong></td>
                <td>{profile.nativePlace}</td>
              </tr>
              <tr>
                <td><strong>Mother Tongue:</strong></td>
                <td>{profile.motherTongue}</td>
              </tr>
              <tr>
                <td><strong>Do you visit Dargah?:</strong></td>
                <td>{profile.dargahVisit}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="button-group">
          <button className="btn-share" onClick={handleShareText}>
            Share as Text
          </button>
          <button className="btn-download" onClick={handleDownloadPDF}>
            Download as PDF
          </button>
        </div>
      </div>
    </section>
  );
};

export default DetailsProfile;
