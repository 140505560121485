import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { loginFormControls } from "../../config/Index";
import CommonForm from "../../common/form";
import { loginUser } from "../../store/auth-slice";


const initialState = {
  Email: "",
  Password: "",
};
const Longin = () => {
  const [formData, setFormData] = useState(initialState);
  const dispatch = useDispatch();
  const navigate = useNavigate();
 

  const onSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    navigate("/user");
 

    dispatch(loginUser(formData))
      .then((data) => {
        // if (data?.payload?.success) {
        //   alert(data.payload.message);
          
        // } else {
        //   alert(data.payload.message || "Login failed. Check credentials.");
        // }
      })
      .catch((error) => {
        alert(error || "An error occurred during login.");
      });
  };
  console.log(formData);
  return (
    <>
      <p className="auth_p">Log In into your Account</p>
      <CommonForm
        formControls={loginFormControls}
        onSubmit={onSubmit}
        formData={formData}
        setFormData={setFormData}
        buttonText={"Login"}
      />
      
      <p className="auth_p2">
        Not yet Registered? <Link to="/auth/register">SignUp</Link>
      </p>
    </>
  );
};

export default Longin;
